import "./swiper.js";

function toggleAnimation(el, isPause) {
  el.classList.toggle("animate-pause", isPause);
}

// use JS instead of css to handle hover
// to be able to resume on scroll on touchscreens
document.addEventListener("touchstart", (e) => {
  let el = e.target;

  if (!el.matches(".animate-pause-hover")) {
    el = el.closest(".animate-pause-hover");
  }

  if (el) toggleAnimation(el);
});

let scrollTimeout;

document.addEventListener("DOMContentLoaded", () => {
  const elements = document.querySelectorAll(".animate-pause-hover");

  elements.forEach((el) => {
    el.addEventListener("mouseleave", () => {
      toggleAnimation(el, false);
    });
  });

  document.addEventListener(
    "scroll",
    () => {
      if (!scrollTimeout) {
        scrollTimeout = setTimeout(() => {
          scrollTimeout = null;

          elements.forEach((el) => toggleAnimation(el, false));
        }, 100);
      }
    },
    { passive: true }
  );
});
